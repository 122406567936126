
	import _prop from 'vue-types';

	export default {
		name: 'app-usp',
		components: {},
		props: {
			items: _prop.array,
		},
		data: () => ({}),
		computed: {},
		methods: {},
	};
