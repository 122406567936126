
	import _prop from 'vue-types';

	import { isEmpty } from '@morev/utils';
	import BaseButton from '~components/_base/base-button/base-button.vue';
	import BaseSection from '~components/_base/base-section/base-section.vue';
	import BaseContainer from '~components/_base/base-container/base-container.vue';
	import ProductsList from '~components/_products/products-list/products-list.vue';
	import ProductsSub from '~components/_products/products-sub/products-sub.vue';
	import CommonPagination from '~components/_common/common-pagination/common-pagination.vue';
	import AppIntersect from '~components/_app/app-intersect/app-intersect.vue';
	import ProductsRowHeader from './_products-row-header/products-row-header.vue';

	const CatalogFilters = () => import('~components/catalog-filters/catalog-filters.vue');

	export default {
		name: 'products-row',
		inheritAttrs: false,
		components: {
			BaseSection,
			BaseContainer,
			BaseButton,
			ProductsList,
			ProductsRowHeader,
			CatalogFilters,
		},
		reactiveProvide: {
			name: '$injected',
			props: true,
		},
		props: {
			loading: _prop.bool.def(false),
			useFilters: _prop.bool.def(false),
			useContainer: _prop.bool.def(false),
			useExtra: _prop.bool.def(false),
			useIntersect: _prop.string.def(''),
			usePagination: _prop.bool.def(false),
			useFavorite: _prop.bool.def(false),
			useBottomButton: _prop.bool.def(false),
			tag: _prop.string.def('section'),
			titleTag: _prop.string.def('h1'),
			titleType: _prop.string.def('primary'),
			title: _prop.string,
			description: _prop.string,
			minPrice: _prop.integer,
			count: _prop.integer,
			items: _prop.array,
			pagination: _prop.object,
			paginationParam: _prop.string.def('page'),
			link: _prop.string,
			isLarge: _prop.bool.def(false),
			isEmpty: _prop.bool.def(false),
			relatedProducts: _prop.array,
			overrides: _prop.object,
			sub: _prop.object,
			notFoundMessages: _prop.object,
			notFoundOptions: _prop.object,
			promoLink: _prop.string,
			sortOptions: _prop.array,
			content: _prop.string,
		},
		emits: ['page-click', 'more-click', 'tag-click'],
		data: () => ({}),
		computed: {
			hasItems() {
				return !!this.items?.length;
			},

			isSubExists() {
				return !isEmpty(this.sub?.items);
			},
		},
		methods: {},
		render() {
			const Header = <ProductsRowHeader class={this.b('header')} content={this.content} />;
			const listModifiers = { loading: this.loading };
			const List = (
				<ProductsList
					class={this.b('list', listModifiers)}
					isLarge={this.isLarge}
					items={this.items}
					useFavorite={this.useFavorite}
					useBottomButton={this.useBottomButton}
					notFoundMessages={this.notFoundMessages}
					notFoundOptions={this.notFoundOptions}
					isEmpty={this.isEmpty}
					promoLink={this.promoLink}
					{...{ attrs: this.overrides.list }}
				/>
			);
			const RelatedProducts = this.isEmpty && this.relatedProducts?.length
				? <div class={this.b('list-wrapper')}>
						<h3 class={this.b('list-title')}>{ this.$t('other-offers') }</h3>
						<ProductsList
							class={this.b('list', listModifiers)}
							items={this.relatedProducts}
							useFavorite={this.useFavorite}
							useBottomButton={this.useBottomButton}
							{...{ attrs: this.overrides.list }}
						/>
					</div>
				: '';
			const Filters = this.useFilters
				? <CatalogFilters class={this.b('filters')} />
				: '';
			const Sub = this.isSubExists
				? <ProductsSub
						items={this.sub.items}
						type={this.sub.type}
						class={this.b('sub')}
						vOn:tag-click={(v) => this.$emit('tag-click', v)}
					/>
				: '';
			const Pagination = this.usePagination && this.items.length
				? <CommonPagination
						usePages
						useMore
						{...{ attrs: this.pagination }}
						paginationParam={this.paginationParam}
						vGlobalLoader={this.loading}
						class={this.b('pagination')}
						vOn:page-click={(page) => this.$emit('page-click', page)}
						vOn:more-click={() => this.$emit('more-click')}
					/>
				: '';
			const MoreButtonWrapper = this.link
				? <div class={this.b('more-button-wrapper')}>
						<BaseButton
							class={this.b('more-button')}
							link={this.link}
							variant="raised"
							tag="a"
							icon="arrows/arrow-right-tailed"
						>
							{ this.$t('show-more') }
						</BaseButton>
					</div>
				: '';

			const content = this.useContainer
				? (
					<BaseContainer class={this.b('container')}>
						{ Header }
						{ Sub }
						{ Filters }
						{
							this.useIntersect
								? (
									<AppIntersect name={this.useIntersect}>
										{ List }
									</AppIntersect>
								)
								: List
						}
						{ Pagination }
						{ MoreButtonWrapper }
						{ RelatedProducts }
					</BaseContainer>
				)
				: [Header, Sub, Filters, List, Pagination, MoreButtonWrapper].filter(Boolean);

			return (
				<BaseSection class={this.b(null)} tag={this.tag}>
					{ content }
				</BaseSection>
			);
		},
	};
