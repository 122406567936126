
	import _prop from 'vue-types';
	import AppTagWithImage from '~components/_app/app-tag-with-image/app-tag-with-image.vue';
	import AppTagWithIcon from '~components/_app/app-tag-with-icon/app-tag-with-icon.vue';
	import AppTags from '~components/_app/app-tags/app-tags.vue';
	import ExternalScrollableBlock from '~components/_external/external-scrollable-block/external-scrollable-block.vue';

	export default {
		name: 'products-sub',
		components: { AppTags, ExternalScrollableBlock, AppTagWithIcon, AppTagWithImage },
		props: {
			type: _prop.oneOf(['tags', 'icons', 'images']).isRequired,
			items: _prop.array,
		},
		emits: ['tag-click'],
		data: () => ({}),
		computed: {
			cItems() {
				return this.items.map(i => ({
					...i,
					active: i.link ? i.link === this.$route.path : i.active,
				}));
			},
		},
		methods: {},
	};
