
	import _prop from 'vue-types';
	import BaseContent from '~components/_base/base-content/base-content.vue';
	import { formatThousands, rub } from '~/scripts/helpers';


	export default {
		name: 'products-row-header',
		components: { BaseContent },
		inject: ['$injected'],
		props: {
			content: _prop.string,
		},
		data: () => ({}),
		computed: {
			isExtraNeeded() {
				return this.$injected.useExtra && (this.$injected.count || this.$injected.minPrice);
			},

			computedPrice() {
				return `${formatThousands(this.$injected.minPrice)} ${rub()}`;
			},
		},
		methods: {},
	};
