
	import _prop from 'vue-types';
	import ModalInfo from '~components/_modals/_modal-info/modal-info.vue';

	export default {
		name: 'info-tooltip',
		components: { ModalInfo },
		props: {
			variant: _prop.oneOf(['icon', 'text']).def('icon'),
			alignment: _prop.oneOf(['center', 'left', 'right']).def('center'),
			triggerText: _prop.string,
			text: _prop.string,
		},
		data: () => ({
			isOpened: false,
		}),
		computed: {
			isOpenedDesktop() {
				return this.isOpened && this.$screen.desktop;
			},

			isOpenedMobile: {
				get() {
					return this.isOpened && !this.$screen.desktop;
				},

				set(v) {
					this.isOpened = v;
				},
			},

			isRenderNeeded() {
				return this.$slots.default || this.text;
			},
		},
		methods: {
			onClick() {
				this.isOpened = !this.isOpened;
			},
		},
	};
