import { render, staticRenderFns } from "./products-list-not-found.vue?vue&type=template&id=aa297ff0"
import script from "./products-list-not-found.vue?vue&type=script&lang=js"
export * from "./products-list-not-found.vue?vue&type=script&lang=js"
import style0 from "./products-list-not-found.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./products-list-not-found.i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fsrv%2Fwww%2Fuflor.ru%2Fhtdocs%2Frelease%2Ffrontend%2Fviews%2Fcomponents%2F_products%2Fproducts-list%2F_products-list-not-found%2Fproducts-list-not-found.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports