
	import _prop from 'vue-types';
	import LazyHydrate from 'vue-lazy-hydration';
	import BaseSection from '~components/_base/base-section/base-section.vue';
	import BaseContainer from '~components/_base/base-container/base-container.vue';
	import ProductsRow from '~components/_products/products-row/products-row.vue';
	import AppUsp from '~components/_app/app-usp/app-usp.vue';
	import { isEmpty } from '@morev/utils';

	export default {
		name: 'products-rich-sections',
		components: {
			LazyHydrate,
			//
			BaseSection,
			BaseContainer,
			ProductsRow,
			AppUsp,
		},
		props: {
			title: _prop.string,
			usp: _prop.array,
			items: _prop.array,
			noTopPad: _prop.bool.def(false),
		},
		data: () => ({}),
		computed: {
			shouldRenderHeader() {
				return this.title || this.hasUsp;
			},

			hasUsp() {
				return !isEmpty(this.usp);
			},
		},
		methods: {},
	};
