
	import _prop from 'vue-types';
	import { debounce } from '@morev/utils';
	import ProductsListNotFound from './_products-list-not-found/products-list-not-found.vue';
	import ProductsListSwiper from './_products-list-swiper/products-list-swiper.vue';
	import ProductsListPlain from './_products-list-plain/products-list-plain.vue';

	export default {
		name: 'products-list',
		components: {
			ProductsListNotFound,
			ProductsListSwiper,
			ProductsListPlain,
		},
		props: {
			useSlider: _prop.bool.def(false),
			usePagination: _prop.bool.def(false),
			useFavorite: _prop.bool.def(false),
			useBottomButton: _prop.bool.def(false),
			promoLink: _prop.string,
			isLarge: _prop.bool.def(false),
			items: _prop.array,
			notFoundMessages: _prop.object,
			notFoundOptions: _prop.object,
			isEmpty: _prop.bool.def(false),
		},
		emits: ['click'],
		data: () => ({}),
		computed: {
			hasItems() {
				return !!this.items?.length;
			},
		},
		methods: {
			setTransformProperties() {
				if (!this.$el) return;

				const block = this.$el.querySelector('.product-item');
				if (!block) return;

				const width = block.clientWidth;
				const scaleFactor = parseFloat(1 + (32 / width)).toPrecision(3);

				const imageWrapper = block.querySelector('[data-image-wrapper]');
				const scaleOrigin = `50% ${parseInt(imageWrapper.clientWidth / 2, 10)}px`;

				const navigationOffset = `${parseInt(imageWrapper.clientWidth / 2, 10)}px`;

				this.$el.style.setProperty('--navigation-offset', navigationOffset);
				this.$el.style.setProperty('--scale-factor', scaleFactor);
				this.$el.style.setProperty('--scale-origin', scaleOrigin);
			},
		},
		created() {
			this.setTransformProperties = debounce(this.setTransformProperties, 300);
		},
		mounted() {
			// Set origin
			['resize', 'orientationchange'].forEach(ev => window.addEventListener(ev, this.setTransformProperties));
			this.setTransformProperties();
		},
		beforeDestroy() {
			['resize', 'orientationchange'].forEach(ev => window.removeEventListener(ev, this.setTransformProperties));
		},
	};
