
	import _prop from 'vue-types';
	import BaseButton from '~components/_base/base-button/base-button.vue';
	import CommonPaginationPages from './_common-pagination-pages/common-pagination-pages.vue';

	export default {
		name: 'common-pagination',
		components: { CommonPaginationPages, BaseButton },
		reactiveProvide: {
			name: '$pagination',
			props: true,
		},
		props: {
			loading: _prop.bool.def(false),
			useMore: _prop.bool.def(false),
			usePages: _prop.bool.def(false),
			count: _prop.number.def(5),
			total: _prop.number.isRequired,
			limit: _prop.number.def(10),
			page: _prop.oneOfType([Number, String]).def(1),
			size: _prop.number.def(5),
			paginationParam: _prop.string.def('page'),
		},
		emits: ['page-click', 'more-click'],
		data: () => ({}),
		computed: {
			computedTotalPages() {
				return Math.ceil(this.total / this.limit);
			},

			hasRemaining() {
				return this.page < this.computedTotalPages;
			},
		},
		methods: {},
	};
