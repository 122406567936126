import { render, staticRenderFns } from "./product-item.vue?vue&type=template&id=186e729f"
import script from "./product-item.vue?vue&type=script&lang=js"
export * from "./product-item.vue?vue&type=script&lang=js"
import style0 from "./product-item.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./product-item.i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fsrv%2Fwww%2Fuflor.ru%2Fhtdocs%2Frelease%2Ffrontend%2Fviews%2Fcomponents%2F_product%2Fproduct-item%2Fproduct-item.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports