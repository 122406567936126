import { render, staticRenderFns } from "./app-usp.vue?vue&type=template&id=151bdd0a"
import script from "./app-usp.vue?vue&type=script&lang=js"
export * from "./app-usp.vue?vue&type=script&lang=js"
import style0 from "./app-usp.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./app-usp.i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fsrv%2Fwww%2Fuflor.ru%2Fhtdocs%2Frelease%2Ffrontend%2Fviews%2Fcomponents%2F_app%2Fapp-usp%2Fapp-usp.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports