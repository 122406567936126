
	import _prop from 'vue-types';
	import ContentBlockWysiwyg from '~components/_content-blocks/content-block-wysiwyg/content-block-wysiwyg.vue';

	export default {
		name: 'base-content-block',
		props: {
			block: _prop.object,
			parentClass: _prop.string,
		},
		data: () => ({}),
		computed: {
			resolvedComponent() {
				if (this.block.type === 'wysiwyg') return ContentBlockWysiwyg;
				return () => import(`~components/_content-blocks/content-block-${this.block.type}/content-block-${this.block.type}.vue`);
			},
		},
		methods: {},
	};
