
	import _prop from 'vue-types';

	export default {
		name: 'product-item-contents',
		components: {},
		inject: ['size', 'width', 'height', 'contents'],
		props: {},
		data: () => ({
			isActive: false,
		}),
		computed: {},
		watch: {},
		methods: {},
	};
