
	import _prop from 'vue-types';
	import FavoritesButton from '~components/_favorites/favorites-button/favorites-button.vue';
	import BaseButton from '~components/_base/base-button/base-button.vue';
	import BaseImg from '~components/_base/base-img/base-img.vue';
	import { isString } from '@morev/utils';
	import { mapStores } from 'pinia';
	import ProductCartButton from '../product-cart-button/product-cart-button.vue';
	import ProductItemContents from './_product-item-contents/product-item-contents.vue';
	import { formatThousands } from '~/scripts/helpers';
	import { useEntityStore } from '~stores/index';

	export default {
		name: 'product-item',
		components: {
			BaseImg, BaseButton, FavoritesButton, ProductItemContents, ProductCartButton,
		},
		provide() {
			return this.$props;
		},
		props: {
			id: _prop.integer,
			link: _prop.string,
			title: _prop.string,
			image: _prop.oneOfType([String, Object]),
			price: _prop.integer,
			orderTime: _prop.string,
			size: _prop.string,
			width: _prop.integer,
			height: _prop.integer,
			contents: _prop.array,
			delivery: _prop.string,
			large: _prop.bool.def(false),
			useFavorite: _prop.bool.def(false),
			useBottomButton: _prop.bool.def(false),
			notAvailable: _prop.bool.def(false),
			description: _prop.string.def(''),
		},
		emits: ['click'],
		data: () => ({}),
		computed: {
			...mapStores(useEntityStore),

			cImgBind() {
				return isString(this.image)
					? {
						src: this.image,
						alt: this.$t('bouquet', { title: this.title }),
					}
					: (this.image || { src: require('~/assets/images/default.png'), alt: '' });
			},

			isContentsExists() {
				const { contents, width, height, size } = this;
				return !!contents?.length || width || height || size;
			},

			cClickableComponent() {
				return this.link ? 'a' : 'button';
			},

			cClickableComponentProps() {
				return this.cClickableComponent === 'a'
					? { href: this.link }
					: { type: this.button };
			},

			cPrice() {
				// Скрытый `<span>` нужен для SEO
				return `${formatThousands(this.price)} ₽ <span style="display: none;">руб</span>`;
			},
		},
		methods: {},
	};
