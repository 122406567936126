import { render, staticRenderFns } from "./common-pagination-pages.vue?vue&type=template&id=7b18aa64"
import script from "./common-pagination-pages.vue?vue&type=script&lang=js"
export * from "./common-pagination-pages.vue?vue&type=script&lang=js"
import style0 from "./common-pagination-pages.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./common-pagination-pages.i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fsrv%2Fwww%2Fuflor.ru%2Fhtdocs%2Frelease%2Ffrontend%2Fviews%2Fcomponents%2F_common%2Fcommon-pagination%2F_common-pagination-pages%2Fcommon-pagination-pages.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports