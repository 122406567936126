
	import _prop from 'vue-types';
	import { mapGetters } from 'vuex';
	import InfoTooltip from '~components/info-tooltip/info-tooltip.vue';
	import BaseButton from '~components/_base/base-button/base-button.vue';

	export default {
		name: 'product-cart-button',
		components: { BaseButton, InfoTooltip },
		props: {
			id: _prop.integer.isRequired,
			partnerId: _prop.integer,
			variant: _prop.oneOf(['default', 'small']).def('default'),
			type: _prop.oneOf(['product', 'extra']).def('product'),
			notAvailable: _prop.bool.def(false),
		},
		data: () => ({
			isMounted: false,
		}),
		computed: {
			...mapGetters('cart', ['isInCart', 'isItemPending']),

			isReallyInCart() {
				const { id, partnerId, type } = this;
				if (type === 'product') return this.isMounted && this.isInCart[id];
				return this.isMounted && this.isInCart[`${id}-${partnerId}`];
			},
		},
		methods: {
			async toggleInCart() {
				const { id, partnerId } = this;
				const type = this.type === 'product' ? 'itemAction' : 'extraAction';
				const method = this.isReallyInCart ? 'delete' : 'post';
				await this.$store.dispatch(`cart/${type}`, { method, data: { id, partnerId } });
				if (this.type === 'product' && this.isReallyInCart) {
					this.$metrika('addmaingoodtobasket');
				}
			},
		},
		mounted() {
			this.isMounted = true;
		},
	};
