
	import _prop from 'vue-types';
	import ExternalIntersect from '~components/_external/external-intersect/external-intersect.vue';

	export default {
		name: 'app-intersect',
		components: {
			ExternalIntersect,
		},
		props: {
			name: _prop.string.isRequired,
			threshold: _prop.array.def([0]),
			rootMargin: _prop.string.def('0px 0px -95% 0px'),
		},
		data: () => ({}),
		computed: {},
		methods: {
			onIntersectionChange([entry]) {
				this.$store.commit('intersection/SET_INTERSECTION_STATE', {
					name: this.name,
					value: entry.isIntersecting,
				});
			},
		},
	};
