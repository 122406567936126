
	import _prop from 'vue-types';
	import LazyHydrate from 'vue-lazy-hydration';
	import BasePage from '~components/_base/base-page/base-page.vue';
	import TheCare from '~components/_the/the-care/the-care.vue';
	import BaseContentBlock from '~components/_base/base-content/_base-content-block/base-content-block.vue';
	import { useCatalogFiltersStore, useGlobalStore } from '~stores/index.js';
	import { mapStores } from 'pinia';
	import ProductsRichSections from '~components/_products/products-rich-sections/products-rich-sections.vue';

	export const asyncData = async (ctx, params) => {
		const globalStore = useGlobalStore(ctx.$pinia);

		return ctx.$api.get({
			'[content] /content': {},
			'[propProductsRichSections] /products-rich-sections': {
				date: globalStore.date.current,
				section_id: null,
			},
		});
	};

	export default {
		name: 'page-index',
		inheritAttrs: false,
		components: {
			LazyHydrate,
			//
			BasePage,
			TheCare,
			ProductsRichSections,
			BaseContentBlock,
		},
		props: {
			// eslint-disable-next-line vue/no-unused-properties -- Used in `data`
			propProductsRichSections: _prop.object,
			content: _prop.array,
		},
		data: (vm) => ({
			isRefetching: false,
			productsRichSections: vm.$props.propProductsRichSections,
		}),
		computed: {
			...mapStores(useGlobalStore),
		},
		watch: {
			'globalStore.date.current': {
				async handler(date) {
					this.isRefetching = true;
					this.productsRichSections = await this.$api.get('/products-rich-sections', { date, section_id: null });
					this.isRefetching = false;
				},
			},
		},
		methods: {},
	};
