
	import _prop from 'vue-types';
	import CommonCrossButton from '~components/_common/common-cross-button/common-cross-button.vue';
	import Modal from '~components/_modals/__modal/modal.vue';

	export default {
		name: 'modal-info',
		inheritAttrs: false,
		components: { Modal, CommonCrossButton },
		props: {},
		emits: [],
		data: () => ({}),
		computed: {},
		methods: {},
	};
