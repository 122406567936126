
	import _prop from 'vue-types';
	import { mapGetters, mapActions } from 'vuex';
	import { isString } from '@morev/utils';

	export default {
		name: 'favorites-button',
		components: {},
		props: {
			id: _prop.integer.isRequired,
			type: _prop.oneOf(['desktop', 'mobile', 'mobile-transparent']).def('desktop'),
			size: _prop.oneOf(['default', 'large']).def('default'),
			fill: _prop.oneOf(['default', 'white']).def('default'),
			image: _prop.oneOfType([String, Object]),
			useSmallParticles: _prop.bool.def(false),
		},
		data: () => ({
			isMounted: false,
		}),
		computed: {
			...mapGetters('favorites', ['isActive', 'isInFavorites', 'isPending']),

			cImageSrc() {
				return isString(this.image) ? this.image : this.image?.src || require('~/assets/images/default.png');
			},
		},
		methods: {
			...mapActions('favorites', ['add', 'remove']),

			onClick() {
				const { id, cImageSrc: image } = this;
				if (this.isPending[id]) return;

				if (this.isInFavorites[id]) {
					this.remove({ id, image });
				} else {
					this.add({ id, image });
				}
			},
		},
		mounted() {
			this.isMounted = true;
		},
	};
