
	import _prop from 'vue-types';
	import ProductItem from '~components/_product/product-item/product-item.vue';

	export default {
		name: 'products-list-plain',
		components: { ProductItem },
		props: {
			items: _prop.array,
			useFavorite: _prop.bool.def(false),
			useBottomButton: _prop.bool.def(false),
		},
		data: (vm) => ({}),
		computed: {},
		watch: {},
		methods: {},
	};
