
	import _prop from 'vue-types';
	import CommonSwiper from '~components/_common/common-swiper/common-swiper.vue';
	import ProductItem from '~components/_product/product-item/product-item.vue';
	import SafeNuxtLink from '~components/safe-nuxt-link/safe-nuxt-link.vue';

	export default {
		name: 'products-list-swiper',
		components: { CommonSwiper, ProductItem, SafeNuxtLink },
		props: {
			useFavorite: _prop.bool.def(false),
			useBottomButton: _prop.bool.def(false),
			promoLink: _prop.string,
			isLarge: _prop.bool.def(false),
			items: _prop.array,
		},
		emits: ['click'],
		data: (vm) => ({
			isMounted: false,
		}),
		computed: {
			cSwiperKey() {
				return this.items.reduce((acc, curr) => {
					return [...acc, curr.id];
				}, []).join('-');
			},

			cSwiperOptions() {
				const { isMounted, $device } = this;

				const base = {
					bemBlock: this.$options.name,
					navigation: true,
					navigationOutside: true,
					navigationWrap: true,
					lazyInit: true,
					disabled: (!isMounted && !$device.isDesktop) || (isMounted && !this.$screen.desktop),
				};

				return this.isLarge
					? {
						...base,
						slidesPerView: 1,
						spaceBetween: 12,
						breakpoints: {
							480: { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 12 },
							768: { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 16 },
							1024: { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 20 },
							1280: { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 32 },
						},
					}
					: {
						...base,
						slidesPerView: 1,
						spaceBetween: 12,
						breakpoints: {
							480: { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 12 },
							768: { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 16 },
							1024: { slidesPerView: 4, slidesPerGroup: 4, spaceBetween: 32 },
						},
					};
			},
		},
		watch: {},
		methods: {},
		mounted() {
			this.isMounted = true;
		},
	};
