
	import _prop from 'vue-types';
	import PageMessage from '~components/page-message/page-message.vue';

	export default {
		name: 'products-list-not-found',
		components: { PageMessage },
		props: {
			notFoundMessages: _prop.object,
			notFoundOptions: _prop.object,
			isEmpty: _prop.bool.def(false),
		},
		data: (vm) => ({}),
		computed: {
			cNotFoundTitle() {
				return this.notFoundMessages?.title ?? this.$t('nothing-found');
			},
		},
		watch: {},
		methods: {},
	};
