
	import Vue from 'vue';

	const warn = (message) => !Vue.config.silent && console.warn(message);

	export default {
		name: 'app-intersect',
		abstract: true,
		props: {
			threshold: {
				type: Array,
				required: false,
				default: () => [0, 0.2],
			},
			root: {
				type: typeof HTMLElement === 'undefined' ? Object : HTMLElement,
				required: false,
				default: () => null,
			},
			rootMargin: {
				type: String,
				required: false,
				default: () => '0px 0px 0px 0px',
			},
		},
		emits: ['enter', 'leave', 'change', 'destroyed'],
		async mounted() {
			this.observer = new IntersectionObserver(([entry]) => {
				const event = entry.isIntersecting ? 'enter' : 'leave';
				this.$emit(event, [entry]);
				this.$emit('change', [entry]);
			}, {
				threshold: this.threshold,
				root: this.root,
				rootMargin: this.rootMargin,
			});

			// No idea why, but using auto-imported transitions
			// they are render as comment for first 3 ticks
			await this.$nextTick();
			await this.$nextTick();
			await this.$nextTick();
			await this.$nextTick();

			if (this.$slots.default && this.$slots.default.length > 1) {
				warn('You may only wrap one element in a `<external-intersect>` component.');
			} else if (!this.$slots.default || this.$slots.default.length < 1) {
				warn('You must have one child inside a `<external-intersect>` component.');
				return;
			}

			this.observer.observe(this.$slots.default[0].elm);
		},
		destroyed() {
			this.$emit('destroyed');
			this.observer.disconnect();
		},
		render() {
			return this.$slots.default ? this.$slots.default[0] : null;
		},
	};
