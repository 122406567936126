
	import _prop from 'vue-types';
	import SafeNuxtLink from '~components/safe-nuxt-link/safe-nuxt-link.vue';
	import BaseImg from '~components/_base/base-img/base-img.vue';

	export default {
		name: 'app-tag-with-image',
		inheritAttrs: false,
		components: { BaseImg, SafeNuxtLink },
		props: {
			lazy: _prop.bool.def(false),
			pixel: _prop.array.def([140, 140]),
			image: _prop.string,
			text: _prop.string,
			active: _prop.bool.def(false),
			link: _prop.string,
		},
		emits: ['click'],
		data: () => ({}),
		computed: {
			computedImageBind() {
				return {
					src: this.image,
					lazy: this.lazy,
					pixel: this.pixel,
					width: this.pixel[0],
					height: this.pixel[1],
				};
			},
		},
		methods: {},
	};
