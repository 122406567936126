
	import _prop from 'vue-types';
	import { clamp } from '@morev/utils';

	export default {
		name: 'common-pagination-pages',
		components: {},
		inject: ['$pagination'],
		props: {},
		emits: ['click'],
		data: () => ({}),
		computed: {
			computedPagesCount() {
				return Math.ceil(this.$pagination.total / this.$pagination.limit);
			},

			computedNavigation() {
				return [
					this.computedArrow('prev'),
					...this.computedNumbers,
					this.computedArrow('next'),
				];
			},

			computedArrow() {
				return (direction) => {
					const disabled = (direction === 'prev')
						? this.$pagination.page === 1
						: this.$pagination.page === this.computedPagesCount;
					const arrowFor = (direction === 'prev') ? this.$pagination.page - 1 : this.$pagination.page + 1;

					return {
						modifiers: { arrow: true, [direction]: true, disabled },
						ariaLabel: this.$t(`${direction}-page`),
						icon: `arrows/arrow-${direction === 'prev' ? 'left' : 'right'}`,
						index: arrowFor,
						href: this.getHref(arrowFor),
						ariaCurrent: false,
						disabled,
						component: disabled ? 'button' : 'a',
					};
				};
			},

			computedNumbers() {
				const { page, size } = this.$pagination;
				const { computedPagesCount } = this;
				const getResult = (first, last) => [...new Array((last + 1) - first).keys()].map(index => {
					const realIndex = index + first;
					const current = realIndex === this.$pagination.page;
					return {
						component: 'a',
						index: realIndex,
						href: this.getHref(realIndex),
						modifiers: { number: true, current },
						ariaLabel: this.$tc('to-page', realIndex),
						text: realIndex,
						disabled: false,
						current,
					};
				});
				const current = clamp(page, 1, computedPagesCount);

				if (computedPagesCount <= size) return getResult(1, computedPagesCount);

				const [beforeCurrent, afterCurrent] = [Math.floor(size / 2), Math.ceil(size / 2) - 1];

				const [first, last] = (current <= beforeCurrent)
					? [1, size] // current page near the start
					: ((current + afterCurrent >= computedPagesCount)
						? [computedPagesCount - size + 1, computedPagesCount] // current page near the end
						: [current - beforeCurrent, current + afterCurrent] // current page somewhere in the middle
					);
				return getResult(first, last);
			},
		},
		methods: {
			getHref(index) {
				if (index < 1 || index > this.computedPagesCount) return false;
				if (index === 1) index = undefined;

				return this.$router.resolve({
					query: { ...this.$route.query, [this.$pagination.paginationParam]: index },
				}).href;
			},

			onClick({ component, index }) {
				if (component === 'button') return;
				this.$emit('click', index);
			},
		},
	};
